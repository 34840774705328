.everification {
  background-color: #5f0404;
  background-image: url("https://www.transparenttextures.com/patterns/ecailles.png");
}

.everification .container {
  position: relative;
  width: 100%;
  padding: 20px;
}

.everification .LOGO-MCCHST {
  height: 180px;
  position: relative;
  margin: 20px;
  margin-bottom: 50px;
  width: 180px;
  cursor: pointer;
}

.everification .cus-button {
  background-color: white;
  color: black;
  font-size: small;
  padding: 5px;
}
