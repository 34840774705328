html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.accounts {
  color: white;
  background-color: #5f0404;
  background-image: url("https://www.transparenttextures.com/patterns/ecailles.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh; /* Ensures it covers the full viewport height */
  box-sizing: border-box; /* Prevents padding from adding to the height */
}

.accounts .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 20px;
}

.accounts .LOGO-MCCHST {
  height: 180px;
  width: 180px;
  margin: 20px 0;
  cursor: pointer;
}

.accounts .cus-button {
  background-color: white;
  color: black;
  font-size: small;
  padding: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  width: 250px;
}

.accounts .user-card {
  background: azure;
  border-radius: 5px;
  color: black;
  width: 300px;
  padding: 10px;
  box-shadow: inset 0px 4px 5px #00000075;
  margin: 10px 0;
  cursor: pointer;
  text-align: center;
}
