.mobile-menu {
  position: absolute;
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  z-index: 10;
}

.mobile-menu .menu-icon {
  top: 0;
  height: 67.43px;
  object-fit: cover;
  position: relative;
  width: 64px;
  cursor: pointer;
}

.mobile-menu .main-menu-bg {
  /* background: #d6d6d6; */
  background-color: #5f0404;
  background-image: url("https://www.transparenttextures.com/patterns/ecailles.png");
  position: relative;
  padding: 20px;
  box-shadow: 0px 13px 30px 5px #00000040;
}

.mobile-menu .menu-bg {
  background-color: #ffffff;
  padding: 10px;
  position: relative;
  margin: 10px;
  width: 170px;
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  box-shadow: 0px 8px 5px 2px #00000040;
}
