.login {
  background-color: #5f0404;
  background-image: url("https://www.transparenttextures.com/patterns/ecailles.png");
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.login .container {
  position: relative;
  width: 100%;
}

.login .LOGO-MCCHST {
  height: 180px;
  position: relative;
  margin: 20px;
  margin-bottom: 50px;
  width: 180px;
  cursor: pointer;
}

.login .cus-button {
  background-color: white;
  color: black;
  font-size: small;
  padding: 5px;
}
