.desktop {
  background-color: #f3f1f1;
  width: 100%;
}

.desktop .head-section {
  position: relative;
  background-color: #5f0404;
  background-image: url("https://www.transparenttextures.com/patterns/ecailles.png");
  box-shadow: 0px 13px 30px 5px #00000040;
  height: 741px;
  width: 100%;
}

.desktop .news-section {
  position: relative;
  background-color: #5f0404;
  background-image: url("https://www.transparenttextures.com/patterns/ecailles.png");
  height: 500px;
  color: white;
}

.desktop .carousel-section {
  width: 100%;
}

.desktop .school-name {
  font-size: 24px;
  font-weight: 900;
}

.desktop .programs-text {
  font-size: 16px;
  font-weight: 900;
}

.desktop .div-2 {
  background-color: #f3f1f1;
  height: 4096px;
  overflow: hidden;
  position: relative;
  width: 1488px;
}

.desktop .welcome-section {
  position: relative;
  width: 100%;
  /* background-color: #800000; */
  background-color: #5f0404;
  background-image: url("https://www.transparenttextures.com/patterns/ecailles.png");
  color: white;
  padding: 20px;
  text-align: left;
}

.desktop .album-container {
  align-items: center;
  display: flex;
  gap: 102px;
  height: 361px;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 212px;
  width: 804px;
}

.desktop .component-1 {
  height: 300px !important;
  position: relative !important;
  width: 200px !important;
}

.desktop .component-instance {
  height: 300px !important;
}

.desktop .component-1-instance {
  height: 123px !important;
  width: 200px !important;
}

.desktop .design-component-instance-node {
  height: 123px !important;
  top: 177px !important;
  width: 200px !important;
}

.desktop .component-2 {
  border-radius: 82.25px/82.09px !important;
  height: 164px !important;
  left: 18px !important;
  top: 61px !important;
  width: 164px !important;
}

.desktop .component-3 {
  height: 123px !important;
  left: 39px !important;
  top: 81px !important;
  width: 122px !important;
}

.desktop .component-4 {
  left: 23px !important;
}

.desktop .component-5 {
  margin-left: -13px !important;
  margin-right: -13px !important;
}

.desktop .component-6 {
  left: 75px !important;
}

.desktop .component-7 {
  margin-left: -2.5px !important;
  margin-right: -2.5px !important;
}

.desktop .component-8 {
  margin-left: -6.5px !important;
  margin-right: -6.5px !important;
}

.desktop .component-9 {
  margin-left: -44.5px !important;
  margin-right: -44.5px !important;
}

.desktop .component-10 {
  margin-left: -19px !important;
  margin-right: -19px !important;
}

.std-image-container {
  border-radius: 100px;
}

.desktop .text-wrapper-3 {
  color: #ffffff;
  font-family: "Inter-Black", Helvetica;
  font-size: 3vw;
  font-weight: 900;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 54px;
}

.desktop .menu-bar {
  display: flex;
  gap: 20px;
  height: 70px;
  justify-content: center;
  right: 40px;
  position: absolute;
  top: 80px;
}

.desktop .menu-button-instance {
  height: 10vw; /* Set the height to 10% of the viewport width */
  width: auto; /* Set the width to 25% of the viewport width */
  cursor: pointer;
}

.desktop .frame-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  height: 317px;
  right: 70px;
  position: absolute;
  top: 380px;
}

.desktop .p {
  align-self: stretch;
  color: #f5f5f5;
  font-family: "Arial-Regular", Helvetica;
  font-size: 1.4vw;
  font-weight: 200;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: justify;
  width: 40vw;
}

.desktop .button-instance {
  height: 47px !important;
  width: 296px !important;
}

.get-started-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px !important;
  width: 296px !important;
  background: #f5e559;
  color: #000000;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.678);
  cursor: pointer;
  font-weight: 500;
}

.desktop .empowering-futures {
  color: #f5f5f5;
  font-family: "Arial-Regular", Helvetica;
  font-size: 2.2vw;
  font-weight: 400;
  height: 80px;
  right: 1.5%;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 217px;
}

.captions {
  color: #f5e559;
  font-family: "Arial-Regular", Helvetica;
  font-size: 2.2vw;
  font-weight: 900;
  height: 80px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  text-shadow: 0px 24px 22.1px #000000a0;
}

.desktop .span {
  color: #f5f5f5;
  font-family: "Arial-Regular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
}

.desktop .text-wrapper-4 {
  font-family: "Arial-Black", Helvetica;
  font-weight: 900;
}

.desktop .motive-main-text {
  font-size: 12px;
}

.desktop .logo-frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 623px;
  left: 2%;
  position: absolute;
  top: 40px;
  width: 38%;
}

.desktop .colleges-title {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: 121px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 100%;
}

.desktop .college-caption {
  color: white;
  flex: 1;
  font-family: "Arbutus-Regular", Helvetica;
  font-size: 2vw;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 20vw;
  /* white-space: nowrap; */
}

.desktop .sub-caption-color {
  color: #f5e559;
  /* font-style: oblique; */
}

.desktop .LOGO-MCCHST {
  align-self: center;
  height: 300px;
  max-width: 70%;
  width: 80%;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.678); /* Horizontal offset, vertical offset, blur radius, color */
}

.desktop .footer-section {
  /* height: 755px; */
  left: 0;
  position: relative;
  width: 100%;
}

.desktop .overlap-3 {
  height: 755px;
  position: relative;
}

.desktop .footer {
  background-color: transparent;
  background-image: url(../../../pictures/footer_img.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 755px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.desktop .footer-bg {
  background-color: #060606cf;
  height: 755px;
  width: 100%;
}

.desktop .img {
  height: 183px;
  left: 0;
  position: absolute;
  top: 572px;
  width: 100%;
}

.desktop .links {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 42px;
  height: 354px;
  left: 30%;
  position: absolute;
  top: 98px;
}

.desktop .text-wrapper-7 {
  color: #ffffff;
  font-family: "Inter-Black", Helvetica;
  font-size: 2.5vw;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.desktop .link {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.desktop .text-wrapper-8 {
  color: #ffffff;
  font-family: "Inter-Black", Helvetica;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
  cursor: pointer;
}

.desktop .text-wrapper-9 {
  color: #ffffff;
  font-family: "Inter-Black", Helvetica;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
  cursor: pointer;
}

.desktop .div-wrapper {
  background: #000000;
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.desktop .text-wrapper-10 {
  align-self: stretch;
  color: #ffffff;
  flex: 1;
  font-family: "Inter-Black", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.desktop .social-media {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 177px;
  right: 2%;
  position: absolute;
  top: 98px;
}

.powered-text {
  font-size: 11px;
  cursor: pointer;
}

.desktop .text-wrapper-11 {
  color: #ffffff;
  font-family: "Inter-Black", Helvetica;
  font-size: 2.5vw;
  font-weight: 900;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -23.08px;
  margin-top: -1px;
  position: relative;
  width: 353.06px;
}

.desktop .frame-4 {
  height: 69.61px;
  margin-left: -4.19px;
  margin-right: -27.94px;
  position: relative;
  width: 362.11px;
}

.desktop .programs-section {
  height: 300px;
  position: relative;
  width: 100%;
  /* background: maroon; */
  background-color: #5f0404;
  background-image: url("https://www.transparenttextures.com/patterns/ecailles.png");
}

.desktop .overlap-group-wrapper {
  height: 885px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.desktop .overlap-group-2 {
  height: 885px;
  position: relative;
}

.desktop .rectangle-2 {
  background-color: #08331f;
  height: 873px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.desktop .admin-block {
  height: 870px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 100%;
}

.desktop .rectangle-3 {
  background-color: #000000d9;
  height: 872px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.desktop .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 498px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 120px;
}

.desktop .text {
  color: #ffffff;
  font-family: "Inter-Black", Helvetica;
  font-size: 16px;
  position: relative;
  text-align: left;
}

.desktop .text-wrapper-12 {
  color: #f5e559;
  font-family: "Inter-Black", Helvetica;
  font-size: 3vw;
  font-weight: 900;
}

.desktop .text-wrapper-13 {
  font-size: 20px;
}

.desktop .text-wrapper-14 {
  font-size: 20px;
}

.desktop .motive-frame {
  /* background: maroon; */
  background-color: #5f0404;
  background-image: url("https://www.transparenttextures.com/patterns/ecailles.png");
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 642px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.desktop .why-choose-muslim {
  align-self: center;
  color: transparent;
  font-family: "Arbutus-Regular", Helvetica;
  font-size: 3vw;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 10px;
  position: relative;
  text-align: center;
  text-shadow: 0px 24px 22.1px #0000008c;
  width: 100%;
}

.desktop .text-wrapper-15 {
  color: #ffffff;
}

.desktop .text-wrapper-16 {
  color: #f5e559;
}

.desktop .motive-section {
  height: 421px;
  position: relative;
  width: 100%;
  padding: 20px;
}

.desktop .motive-section-2 {
  align-items: center;
  display: flex;
  gap: 2%;
  height: 421px;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.desktop .global-prospect {
  align-items: flex-end;
  background-color: #ffffff;
  box-shadow: inset 0px 4px 45px #00000075;
  display: inline-flex;
  flex: 0 0 auto;
  height: 348px;
  justify-content: center;
  padding: 20px 20px 64px;
  position: relative;
  width: 28%;
}

.desktop .div-4 {
  color: #000000;
  font-family: "Arial-Regular", Helvetica;
  font-size: 8vw; /* Adjust the value as needed */
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: justify;
  width: 100%; /* Adjust the width as needed */
}

.desktop .text-wrapper-17 {
  color: #000000;
  font-family: "Arial-Regular", Helvetica;
  font-size: 8vw; /* Adjust the value as needed */
  font-weight: 400;
  top: 20px;
}

.desktop .text-wrapper-18 {
  font-family: "Arial-Black", Helvetica;
  font-size: 2vw; /* Adjust the value as needed */
  font-weight: 900;
}

.desktop .integrity {
  align-items: flex-end;
  background-color: white;
  border-radius: 30px;
  box-shadow: inset 0px 4px 45px #00000075;
  display: inline-flex;
  flex: 0 0 auto;
  height: 348px;
  justify-content: center;
  padding: 20px 20px 43px;
  position: relative;
  width: 28%;
}

.desktop .inclusive-community {
  align-items: flex-end;
  background-color: #ffffff;
  box-shadow: inset 0px 4px 45px #00000075;
  display: inline-flex;
  flex: 0 0 auto;
  height: 348px;
  justify-content: center;
  padding: 20px;
  position: relative;
  width: 28%;
}

.icons {
  width: 48px;
  height: 48x;
  margin: 10px;
  margin-right: 30px;
  cursor: pointer;
}
