.cus-button {
  padding-left: 20px;
  padding-right: 20px;
  padding: 2px;
  margin-top: 5px;
  background-color: #5f0404;
  border-radius: 20px;
  color: white;
  width: 150px;
  position: relative;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
}

.cus-date-picker {
  border-radius: 10px;
}

.m-btn-color {
  background: #5f0404;
  background-color: #5f0404;
}
